import React from 'react';
import Layout from '../components/layout';
import { useIntl } from 'gatsby-plugin-intl';
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbAnchor,
} from '../components/breadcrumb';

import Seo from '../components/seo';

type MarkdownTemplateProps = {
  pageContext: {
    title: string;
    html: string;
    language: string;
  };
};

const MarkdownTemplate = ({ pageContext }: MarkdownTemplateProps) => {
  const { title, html, language } = pageContext;
  const intl = useIntl();

  return (
    <Layout lang={language}>
      <Seo title={title} lang={language} />
      <Breadcrumb className="breadcrumb--workbench">
        <BreadcrumbAnchor
          label={intl.formatMessage({ id: 'home', defaultMessage: 'home' })}
          url="/"
        />
        <BreadcrumbDivider />
        <BreadcrumbAnchor active label={title} />
      </Breadcrumb>
      <div className="markdown">
        <div className="markdown__wrap">
          <div
            className="markdown__content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default MarkdownTemplate;
